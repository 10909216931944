#ParticleBackground {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, #03738C 0%, #011624 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

#ParticleDiv {
  display: none;
}

#BackgroundCanvas {
  width: 100%;
  height: 100%;
}